<template>
  <div :class="list.length > 1 ? 'zq--metadata' : ''">
    <template v-for="(field, index) in list">
      <CRow form>
        <CCol sm="8" md="8" lg="8">
          <ClRowInput
            :type="type"
            name="metakey"
            v-model="field.val"
            :placeholder="type === 'text' ? 'Enter Text' : 'Enter Number'"
            :disabled="false"
          />
        </CCol>

        <CCol sm="4" md="4" lg="4" class="zq--metadata--actions">
          <CButton
            type="button"
            :color="setBtnColor('danger')"
            size="sm"
            class="zq--small-button"
            @click="deleteField(index)"
            :disabled="list.length === 1"
          >
            <CIcon name="cil-minus" />
          </CButton>
          <CButton
            type="button"
            :color="setBtnColor('success')"
            class="zq--small-button ml-2"
            size="sm"
            :disabled="!field.val"
            @click="addField(index)"
            v-if="list.length - 1 === index"
          >
            <CIcon name="cil-plus" />
          </CButton>
        </CCol>
      </CRow>
    </template>
  </div>
</template>

<script>
import ClRowInput from '@/components/forms/ClRowInput';
export default {
  components: {
    ClRowInput,
  },
  props: {
    limit: { type: Number, default: 5 },
    value: Array,
    type: { type: String, default: 'text' },
  },
  methods: {
    setBtnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
    addField() {
      this.list.push({ val: '' });
    },
    deleteField(i) {
      if (this.list.length > 1) {
        this.list.splice(i, 1);
      }
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    if (this.value) {
      this.value.forEach((x) => {
        this.list.push({ val: x });
      });
    } else {
      this.addField();
    }
  },
  watch: {
    list: {
      deep: true,
      handler() {
        const arr = [];
        this.list.forEach((x) => {
          x.val !== '' ? arr.push(x.val) : null;
        });
        this.$emit('input', arr);
      },
    },
  },
};
</script>
